export const START_USER_AUTHENTICATION = 'START_USER_AUTHENTICATION';
export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS';
export const USER_AUTH_FAILURE = 'USER_AUTH_FAILURE';

export const START_USER_LOGOUT = 'START_USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export const UPDATE_USER = 'UPDATE_USER'

export const UPDATE_SUBSCRIBER = 'UPDATE_SUBSCRIBER'
export const UPDATE_SUBSCRIBER_TOKEN = 'UPDATE_SUBSCRIBER_TOKEN'


export const START_SUBSCRIBER_AUTHENTICATION = 'START_SUBSCRIBER_AUTHENTICATION';
export const SUBSCRIBER_AUTH_SUCCESS = 'SUBSCRIBER_AUTH_SUCCESS';
export const SUBSCRIBER_AUTH_FAILURE = 'USUBSCRIBERAUTH_FAILURE';

export const START_SUBSCRIBER_LOGOUT = 'START_SUBSCRIBER_LOGOUT';
export const SUBSCRIBER_LOGOUT_SUCCESS = 'SUBSCRIBER_LOGOUT_SUCCESS';
export const SUBSCRIBER_LOGOUT_FAILURE = 'SUBSCRIBER_LOGOUT_FAILURE';
// export const START_USER_REGISTRATION = 'START_USER_REGISTRATION';
// export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
// export const USER_REGISTRATION_FAILURE = 'USER_REGISTRATION_FAILURE';
// export const GET_USER_ROLE = 'GET_USER_ROLE';
