



export const START_FETCHING_REPORT= 'START_FETCHING_REPORT';
export const FETCHING_REPORT_SUCCESS = 'FETCHING_REPORT_SUCCESS';
export const FETCHING_REPORT_FAILURE = 'FETCHING_REPORT_FAILURE';
export const REPORT_SPINNER_STARTS = 'REPORT_SPINNER_STARTS'
export const REPORT_SPINNER_STOPS = 'REPORT_SPINNER_STOPS'
export const START_FETCHING_REPORT_WITH_SPINNER = 'START_FETCHING_REPORT_WITH_SPINNER'


export const START_FETCHING_CASE= 'START_FETCHING_CASE';
export const FETCHING_CASE_SUCCESS = 'FETCHING_CASE_SUCCESS';
export const FETCHING_CASE_FAILURE = 'FETCHING_CASE_FAILURE';
export const CASE_SPINNER_STARTS = 'CASE_SPINNER_STARTS'
export const CASE_SPINNER_STOPS = 'CASE_SPINNER_STOPS'
export const START_FETCHING_CASE_WITH_SPINNER = 'START_FETCHING_CASE_WITH_SPINNER'




export const START_FETCHING_ARTICLE= 'START_FETCHING_ARTICLE';
export const FETCHING_ARTICLE_SUCCESS = 'FETCHING_ARTICLE_SUCCESS';
export const FETCHING_ARTICLE_FAILURE = 'FETCHING_ARTICLE_FAILURE';
export const ARTICLE_SPINNER_STARTS = 'ARTICLE_SPINNER_STARTS'
export const ARTICLE_SPINNER_STOPS = 'ARTICLE_SPINNER_STOPS'
export const START_FETCHING_ARTICLE_WITH_SPINNER = 'START_FETCHING_ARTICLE_WITH_SPINNER'