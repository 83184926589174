import React from "react";
import "./copyright.styles.scss";

export default function Copyright() {
  return (
    <div className="container-fluid copyright-section">
      <div className="container text-section">
        <p>
          © Copyright by CVWN Nepal 2022 | Design by{" "}
          <a href="https://logincorps.com" target="_blank">LOGIN</a>
        </p>
      </div>
    </div>
  );
}
