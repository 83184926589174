export const START_FETCHING_LANDING_HERO = "START_FETCHING_LANDING_HERO";
export const FETCHING_LANDING_HERO_SUCCESS = "FETCHING_LANDING_HERO_SUCCESS";
export const FETCHING_LANDING_HERO_FAILURE = "FETCHING_LANDING_HERO_FAILURE";
export const LANDING_HERO_SPINNER_STARTS = "LANDING_HERO_SPINNER_STARTS";
export const LANDING_HERO_SPINNER_STOPS = "LANDING_HERO_SPINNER_STOPS";
export const START_FETCHING_LANDING_HERO_WITH_SPINNER =
  "START_FETCHING_LANDING_HERO_WITH_SPINNER";



  export const START_FETCHING_LANDING_SLIDER = "START_FETCHING_LANDING_SLIDER";
  export const FETCHING_LANDING_SLIDER_SUCCESS = "FETCHING_LANDING_SLIDER_SUCCESS";
  export const FETCHING_LANDING_SLIDER_FAILURE = "FETCHING_LANDING_SLIDER_FAILURE";
  export const LANDING_SLIDER_SPINNER_STARTS = "LANDING_SLIDER_SPINNER_STARTS";
  export const LANDING_SLIDER_SPINNER_STOPS = "LANDING_SLIDER_SPINNER_STOPS";
  export const START_FETCHING_LANDING_SLIDER_WITH_SPINNER =
    "START_FETCHING_LANDING_SLIDER_WITH_SPINNER";



export const START_FETCHING_LANDING_DONATE = "START_FETCHING_LANDING_DONATE";
export const FETCHING_LANDING_DONATE_SUCCESS = "FETCHING_LANDING_DONATE_SUCCESS";
export const FETCHING_LANDING_DONATE_FAILURE = "FETCHING_LANDING_DONATE_FAILURE";
export const LANDING_DONATE_SPINNER_STARTS = "LANDING_DONATE_SPINNER_STARTS";
export const LANDING_DONATE_SPINNER_STOPS = "LANDING_DONATE_SPINNER_STOPS";
export const START_FETCHING_LANDING_DONATE_WITH_SPINNER =
  "START_FETCHING_LANDING_DONATE_WITH_SPINNER";

export const START_FETCHING_LANDING_ABOUT = "START_FETCHING_LANDING_ABOUT";
export const FETCHING_LANDING_ABOUT_SUCCESS =
  "FETCHING_LANDING_ABOUT_SUCCESS";
export const FETCHING_LANDING_ABOUT_FAILURE =
  "FETCHING_LANDING_ABOUT_FAILURE";
export const LANDING_ABOUT_SPINNER_STARTS = "LANDING_ABOUT_SPINNER_STARTS";
export const LANDING_ABOUT_SPINNER_STOPS = "LANDING_ABOUT_SPINNER_STOPS";
export const START_FETCHING_LANDING_ABOUT_WITH_SPINNER =
  "START_FETCHING_LANDING_ABOUT_WITH_SPINNER";

export const START_FETCHING_TEAM = "START_FETCHING_TEAM";
export const FETCHING_TEAM_SUCCESS = "FETCHING_TEAM_SUCCESS";
export const FETCHING_TEAM_FAILURE = "FETCHING_TEAM_FAILURE";
export const TEAM_SPINNER_STARTS = "TEAM_SPINNER_STARTS";
export const TEAM_SPINNER_STOPS = "TEAM_SPINNER_STOPS";
export const START_FETCHING_TEAM_WITH_SPINNER =
  "START_FETCHING_TEAM_WITH_SPINNER";

export const START_FETCHING_TESTIMONIAL = "START_FETCHING_TESTIMONIAL";
export const FETCHING_TESTIMONIAL_SUCCESS = "FETCHING_TESTIMONIAL_SUCCESS";
export const FETCHING_TESTIMONIAL_FAILURE = "FETCHING_TESTIMONIAL_FAILURE";
export const TESTIMONIAL_SPINNER_STARTS = "TESTIMONIAL_SPINNER_STARTS";
export const TESTIMONIAL_SPINNER_STOPS = "TESTIMONIAL_SPINNER_STOPS";
export const START_FETCHING_TESTIMONIAL_WITH_SPINNER =
  "START_FETCHING_TESTIMONIAL_WITH_SPINNER";





export const START_FETCHING_PARTNER = "START_FETCHING_PARTNER";
export const FETCHING_PARTNER_SUCCESS = "FETCHING_PARTNER_SUCCESS";
export const FETCHING_PARTNER_FAILURE = "FETCHING_PARTNER_FAILURE";
export const PARTNER_SPINNER_STARTS = "PARTNER_SPINNER_STARTS";
export const PARTNER_SPINNER_STOPS = "PARTNER_SPINNER_STOPS";
export const START_FETCHING_PARTNER_WITH_SPINNER =
  "START_FETCHING_PARTNER_WITH_SPINNER";



  export const START_FETCHING_MEMBER = "START_FETCHING_MEMBER";
  export const FETCHING_MEMBER_SUCCESS = "FETCHING_MEMBER_SUCCESS";
  export const FETCHING_MEMBER_FAILURE = "FETCHING_MEMBER_FAILURE";
  export const MEMBER_SPINNER_STARTS = "MEMBER_SPINNER_STARTS";
  export const MEMBER_SPINNER_STOPS = "MEMBER_SPINNER_STOPS";
  export const START_FETCHING_MEMBER_WITH_SPINNER =
    "START_FETCHING_MEMBER_WITH_SPINNER";