
import image1 from '../event1.jpg'
import image2 from '../event4.jpg'
export const articleData = [
    {
        id:1,
        articletitle:"Strengthening Women Voice",
        articleimage: image1,
        articledescription:"An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press. ",    
    },
    {
        id:2,
        articletitle:"Women in Leadership",
        articleimage: image2,
        articledescription:"An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.",       
    },
    {
        id:3,
        articletitle:"१० वर्षपछि लाश मिल्यो तर अझै न्याय मिलेन सार्वजनिकीकरण",
        articleimage: image1,
        articledescription:"An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.",
    },
    {
        id:4,
        articletitle:"Women in Leadership",
        articleimage: image2,
        articledescription:"An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.",        
    },
    {
        id:5,
        articletitle:"१० वर्षपछि लाश मिल्यो तर अझै न्याय मिलेन सार्वजनिकीकरण",
        articleimage: image1,
        articledescription:"An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.",        
    },
    {
        id:6,
        articletitle:"Strengthening Women Voice",
        articleimage: image2,
        articledescription:"An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.An event description is a text or copy that tells audiences all the essential details about your event. These details should come together so that it compels potential attendees to register. But more than driving up attendance, a good event description can pique the interest of non-members and even the press.",        
    }
    
]