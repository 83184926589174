



export const START_FETCHING_PROJECT = 'START_FETCHING_PROJECT';
export const FETCHING_PROJECT_SUCCESS = 'FETCHING_PROJECT_SUCCESS';
export const FETCHING_PROJECT_FAILURE = 'FETCHING_PROJECT_FAILURE';

export const PROJECT_SPINNER_STARTS = 'PROJECT_SPINNER_STARTS'
export const PROJECT_SPINNER_STOPS = 'PROJECT_SPINNER_STOPS'
export const START_FETCHING_PROJECT_WITH_SPINNER = 'START_FETCHING_PROJECT_WITH_SPINNER'